<template>
  <div>
    <header-main></header-main>
    <div class="politics-pro-data">
      <div class="politics-pro-data__content-h1">
        <h1 class="politics-pro-data__h1">POLÍTICAS DE PRIVACIDAD</h1>
      </div>
      <div class="politics-pro-data__content">
        <b class="politics-pro-data__parrafos politics-pro-data__center"
          >CAPÍTULO I DISPOSICIONES GENERALES</b
        >
        <p></p>
        <p class="politics-pro-data__parrafos">
          <b>ARTÍCULO 1.</b> DEFINICIONES. Para efectos de la aplicación de las
          reglas contenidas en la presente política y de acuerdo con lo
          establecido en el Artículo 3 de la Ley 1581 de 2012 se entiende por:
        </p>
        <p class="politics-pro-data__parrafos">
          a) Autorización: Consentimiento previo, expreso e informado del
          Titular para llevar a cabo el Tratamiento de datos personales.
        </p>
        <p class="politics-pro-data__parrafos">
          b) Aviso de privacidad: Comunicación verbal o escrita generada por el
          Responsable dirigida al Titular para el tratamiento de sus datos
          personales, mediante la cual se le informa acerca de la existencia de
          las políticas de Tratamiento de información que le serán aplicables,
          la forma de acceder a las mismas y las finalidades del Tratamiento que
          se pretende dar a los datos personales.
        </p>
        <p class="politics-pro-data__parrafos">
          c) Base de Datos: Conjunto organizado de datos personales que sea
          objeto de Tratamiento.
        </p>
        <p class="politics-pro-data__parrafos">
          d) Dato personal: Cualquier información vinculada o que pueda
          asociarse a una o varias personas naturales determinadas o
          determinables.
        </p>
        <p class="politics-pro-data__parrafos">
          e) Dato privado: Es el dato que por su naturaleza íntima o reservada
          sólo es relevante para el titular.
        </p>
        <p class="politics-pro-data__parrafos">
          f) Datos sensibles: Se entiende por datos sensibles aquellos que
          afectan la intimidad del Titular o cuyo uso indebido puede generar su
          discriminación, tales como aquellos que revelen el origen racial o
          étnico, la orientación política, las convicciones religiosas o
          filosóficas, la pertenencia a sindicatos, organizaciones sociales, de
          derechos humanos o que promueva intereses de cualquier partido
          político o que garanticen los derechos y garantías de partidos
          políticos de oposición, así como los datos relativos a la salud, a la
          vida sexual y los datos biométricos.
        </p>
        <p class="politics-pro-data__parrafos">
          g) Encargado del Tratamiento: Persona natural o jurídica, pública o
          privada, que por sí misma o en asocio con otros, realice el
          Tratamiento de datos personales por cuenta del Responsable del
          Tratamiento.
        </p>
        <p class="politics-pro-data__parrafos">
          h) Responsable del Tratamiento: Persona natural o jurídica, pública o
          privada, que por sí misma o en asocio con otros, decida sobre la base
          de datos y/o el Tratamiento de los datos.
        </p>
        <p class="politics-pro-data__parrafos">
          i) Titular: Persona natural cuyos datos personales sean objeto de
          Tratamiento.
        </p>
        <p class="politics-pro-data__parrafos">
          j) Tratamiento: Cualquier operación o conjunto de operaciones sobre
          datos personales, tales como la recolección, almacenamiento, uso,
          circulación o supresión de los mismos.
        </p>

        <p class="politics-pro-data__parrafos">
          <b>ARTICULO 2.</b> OBJETO. El presente documento tiene la finalidad de
          regular los procedimientos de recolección, manejo y tratamiento de los
          datos de carácter personal que realiza APPEARS S.A.S, a fin de
          garantizar y proteger el derecho fundamental de habeas data de sus
          huéspedes, visitantes, clientes, usuarios y proveedores en el marco de
          lo establecido en la ley.
        </p>
        <p class="politics-pro-data__parrafos">
          Todo lo anterior en cumplimiento a lo previsto en el literal (k) del
          Artículo 17 de la Ley 1581 de 2012, que regula los deberes que asisten
          a los responsables del tratamiento de datos personales, dentro de los
          cuales se encuentra el de adoptar un manual interno de políticas y
          procedimientos para garantizar el adecuado cumplimiento de la ley y en
          especial, para la atención de consultas y reclamos.
        </p>

        <p class="politics-pro-data__parrafos">
          ARTÍCULO 3. ÁMBITO DE APLICACIÓN. El presente manual será aplicable a
          los datos personales registrados y por registrar en las diferentes
          bases de datos manejadas por APPEARS S.A.S esto es, a las bases de
          datos de nuestros huéspedes, visitantes, clientes y proveedores, que
          nos suministren sus datos para fines comerciales.
        </p>
        <p class="politics-pro-data__parrafos">
          La información que recolecta APPEARS S.A.S, puede incluir, en todo o
          en parte según las necesidades de cada producto y/o servicio, entre
          otros los siguientes datos:
        </p>
        <ul>
          <li class="politics-pro-data__parrafos">Nombres y apellidos.</li>
          <li class="politics-pro-data__parrafos">
            Tipo y número de identificación.
          </li>
          <li class="politics-pro-data__parrafos">
            Nacionalidad y país de residencia.
          </li>
          <li class="politics-pro-data__parrafos">
            Fecha de nacimiento y género.
          </li>
          <li class="politics-pro-data__parrafos">
            Estado civil y/o parentesco en relación con personas menores de edad
            o discapacitados solicitantes de nuestros servicios.
          </li>
          <li class="politics-pro-data__parrafos">
            Teléfonos fijos y celulares de contacto (personales y/o laborales).
          </li>
          <li class="politics-pro-data__parrafos">
            Direcciones postales y electrónicas (personales y/o laborales).
          </li>
          <li class="politics-pro-data__parrafos">Profesión u oficio.</li>
          <li class="politics-pro-data__parrafos">
            Compañía en la que labora y cargo.
          </li>
          <li class="politics-pro-data__parrafos">Procedencia y destino.</li>
          <li class="politics-pro-data__parrafos">Motivo de su viaje.</li>
          <li class="politics-pro-data__parrafos">
            Información de tarjeta(s) de crédito (número, entidad bancaria,
            fecha de vencimiento).
          </li>
          <li class="politics-pro-data__parrafos">
            Datos personales del tarjetahabiente (nombres y apellidos, tipo y
            número de identificación).
          </li>
          <li class="politics-pro-data__parrafos">
            Información del domicilio donde el tarjetahabiente recibe sus
            extractos bancarios.
          </li>
        </ul>
        <p class="politics-pro-data__parrafos">
          Estos datos pueden ser almacenados y/o procesados en servidores
          ubicados en centros de cómputo, ya sean propios o contratados con
          terceros proveedores, lo cual es autorizado por nuestros huéspedes,
          visitantes, clientes, usuarios y proveedores al aceptar esta Política
          de Privacidad.
        </p>

        <p class="politics-pro-data__parrafos">
          <b>ARTÍCULO 4.</b> VERACIDAD DE LA INFORMACIÓN. Nuestros huéspedes,
          visitantes, clientes, usuarios y proveedores deberán suministrar
          información veraz sobre sus datos personales para efectos de hacer
          posible la prestación de los servicios por parte de APPEARS S.A.S y
          bajo cuya condición aceptan entregar la información requerida.
        </p>
        <p class="politics-pro-data__parrafos">
          APPEARS S.A.S presume la veracidad de la información suministrada y no
          verifica, ni asume la obligación de verificar, la identidad del
          huéspedes, visitantes, clientes, usuarios y proveedores, ni la
          veracidad, vigencia, suficiencia y autenticidad de los datos que cada
          uno de ellos proporcione.
        </p>
        <p class="politics-pro-data__parrafos">
          Por tanto, no asume responsabilidad por daños y/o perjuicios de toda
          naturaleza que pudieran tener origen en la falta de veracidad,
          vigencia, suficiencia o autenticidad de la información, incluyendo
          daños y perjuicios que puedan deberse a la homonimia o a la
          suplantación de la identidad.
        </p>

        <p class="politics-pro-data__parrafos">
          <b>ARTÍCULO 5.</b> LEGISLACIÓN APLICABLE. Este manual fue elaborado
          teniendo en cuenta las ordenanzas de la Ley 1581 de 2012 “Por la cual
          se dictan disposiciones generales para la protección de datos
          personales” y del Decreto número 1377 de 2013 “Por el cual se
          reglamenta parcialmente la Ley 1581 de 2012”.
        </p>

        <p class="politics-pro-data__parrafos">
          <b>ARTICULO 6.</b> INFORMACIÓN DE NIÑOS Y ADOLESCENTES MENORES DE EDAD
          APPEARS S.A.S velará por el uso adecuado de los datos personales de
          los niños, niñas y adolescentes menores de edad, garantizando que en
          el tratamiento de sus datos se respete el interés superior de ellos, y
          sus derechos fundamentales y en lo posible, teniendo en cuenta su
          opinión, como titulares de sus datos personales.
        </p>

        <p class="politics-pro-data__parrafos">
          <b>ARTICULO 7.</b> FINALIDADES DEL TRATAMIENTO DE LOS DATOS
          PERSONALES. La información recolectada es utilizada para procesar,
          confirmar, cumplir y proveer los servicios y/o productos adquiridos,
          directamente y/o con la participación de terceros proveedores de
          productos o servicios, así como para promocionar y publicitar nuestras
          actividades, productos y servicios, realizar transacciones, efectuar
          reportes a las distintas autoridades administrativas de control y
          vigilancia nacionales o internacionales, autoridades de policía o
          autoridades judiciales, entidades bancarias y/o compañías
          aseguradoras, para fines administrativos internos y/o comerciales
          tales como, investigación de mercados, auditorías, reportes contables,
          análisis estadísticos, facturación, y ofrecimiento y/o reconocimiento
          de beneficios propios de nuestros programas de lealtad.
        </p>
        <p class="politics-pro-data__parrafos">
          Al aceptar esta Política de Privacidad y Tratamiento, nuestros
          huéspedes, visitantes, clientes, usuarios y proveedores en su calidad
          de titulares de los datos recolectados, autorizan que APPEARS S.A.S
          realicen el tratamiento de los mismos, de forma parcial o total,
          incluyendo la recolección, almacenamiento, grabación, uso,
          circulación, procesamiento, supresión, para la ejecución de las
          actividades relacionadas con los servicios y productos adquiridos,
          tales como, realización de reservas, modificaciones, cancelaciones y
          cambios de la misma, reembolsos, atención de consultas, quejas y
          reclamos, pago de compensaciones e indemnizaciones, registros
          contables, correspondencia, procesamiento y verificación de tarjetas
          de crédito, débito y otros instrumentos de pago, identificación de
          fraudes y prevención de lavado de activos y de otras actividades
          delictivas y/o para el funcionamiento de los programas de lealtad y
          demás finalidades indicadas en este documento.
        </p>
        <p class="politics-pro-data__parrafos">
          Lo anterior, sin perjuicio de otras finalidades que hayan sido
          informadas en este documento y en los términos y condiciones de cada
          uno de los productos y servicios propios de cada una de nuestras
          unidades de negocio.
        </p>
        <p class="politics-pro-data__parrafos">
          Adicionalmente, nuestros viajeros, clientes y usuarios, en su calidad
          de titulares de los datos recolectados, al aceptar esta política de
          privacidad, nos autorizan para:
        </p>
        <ul>
          <li class="politics-pro-data__parrafos">
            Utilizar la información recibida de ellos, para fines de mercadeo de
            sus productos y servicios, y de los productos y servicios de
            terceros con los que APPEARS S.A.S mantengan una relación de
            negocios.
          </li>
          <li class="politics-pro-data__parrafos">
            Suministrar los datos personales a las autoridades de control y
            vigilancia de policía o judiciales, en virtud de un requerimiento
            legal o reglamentario y/o usar o revelar esta información y datos
            personales en defensa de sus derechos y/o su patrimonio en cuanto
            dicha defensa tenga relación con los productos y/o servicios
            contratados por sus viajeros, clientes y usuarios.
          </li>
          <li class="politics-pro-data__parrafos">
            Permitir el acceso a la información y datos personales a los
            auditores o terceros contratados para llevar a cabo procesos de
            auditoría interna o externa propios de la actividad comercial que
            desarrollamos.
          </li>
          <li class="politics-pro-data__parrafos">
            Consultar y actualizar los datos personales, en cualquier tiempo,
            con el fin de mantener actualizada dicha información.
          </li>
          <li class="politics-pro-data__parrafos">
            Contratar con terceros el almacenamiento y/o procesamiento de la
            información y datos personales para la correcta ejecución de los
            contratos celebrados con nosotros, bajo los estándares de seguridad
            y confidencialidad a los cuales estamos obligados.
          </li>
        </ul>
        <p></p>
        <b class="politics-pro-data__parrafos politics-pro-data__center"
          >CAPÍTULO II AUTORIZACIÓN</b
        >
        <p></p>
        <p class="politics-pro-data__parrafos">
          <b>ARTÍCULO 8.</b> AUTORIZACIÓN. La recolección almacenamiento, uso,
          circulación o supresión de datos personales por parte de APPEARS S.A.S
          requiere del consentimiento libre, previo, expreso e informado del
          titular de los mismos. APPEARS S.A.S, en su condición de responsable
          del tratamiento de datos personales, ha dispuesto de los mecanismos
          necesarios para obtener la autorización de los titulares garantizando
          en todo caso que sea posible verificar el otorgamiento de dicha
          autorización.
        </p>
        <p class="politics-pro-data__parrafos">
          Con la mencionada autorización, el cliente acepta las políticas y
          condiciones que se establecen en el presente documento.
        </p>

        <p class="politics-pro-data__parrafos">
          <b>ARTÍCULO 9.</b> FORMA Y MECANISMOS PARA OTORGAR LA AUTORIZACIÓN. La
          autorización del titular de la información constará en cada uno de los
          canales y mecanismos de recolección de datos dAPPEARS S.A.S así, podrá
          constar en un documento físico, electrónico o en cualquier otro
          formato que permita garantizar su posterior consulta. La autorización
          será emitida por el titular previo al tratamiento de sus datos
          personales, de conformidad con lo dispuesto en la Ley 1581 de 2012.
        </p>
        <p class="politics-pro-data__parrafos">
          Con el procedimiento de autorización consentida se garantiza que se ha
          puesto en conocimiento del titular de los datos personales, tanto el
          hecho que su información personal será recogida y utilizada para fines
          determinados y conocidos, como que tiene la opción de conocer
          cualquier alternación a los mismos y el uso específico que de ellos se
          ha dado. Lo anterior con el fin de que el titular tome decisiones
          informadas con relación a sus datos personales y controle el uso de su
          información personal.
        </p>
        <p></p>
        <b class="politics-pro-data__parrafos politics-pro-data__center"
          >CAPITULO III DERECHOS Y DEBERES</b
        >
        <p></p>
        <p class="politics-pro-data__parrafos">
          <b>ARTÍCULO 10.</b> DERECHOS DE LOS TITULARES DE LA INFORMACIÓN. De
          conformidad con lo establecido en el artículo 8 de la Ley 1581 de 2012
          el titular de los datos personales tiene los siguientes derechos:
        </p>
        <p class="politics-pro-data__parrafos">
          a) Conocer, actualizar y rectificar sus datos personales frente al
          APPEARS S.A.S, en su condición de responsable del tratamiento.
        </p>
        <p class="politics-pro-data__parrafos">
          b) Solicitar prueba de la autorización otorgada al APPEARS S.A.S, en
          su condición de Responsable del Tratamiento.
        </p>
        <p class="politics-pro-data__parrafos">
          c) Ser informado por APPEARS S.A.S previa solicitud, respecto del uso
          que le ha dado a sus datos personales.
        </p>
        <p class="politics-pro-data__parrafos">
          d) Presentar ante la Superintendencia de Industria y Comercio quejas
          por infracciones a lo dispuesto en la Ley 1581 de 2012, una vez haya
          agotado el trámite de consulta o reclamo ante el Responsable del
          Tratamiento.
        </p>
        <p class="politics-pro-data__parrafos">
          e) Revocar la autorización y/o solicitar la supresión del dato cuando
          en el Tratamiento no se respeten los principios, derechos y garantías
          constitucionales y legales.
        </p>
        <p class="politics-pro-data__parrafos">
          f) Acceder en forma gratuita a sus datos personales que hayan sido
          objeto de Tratamiento.
        </p>

        <p class="politics-pro-data__parrafos">
          <b>ARTÍCULO 11.</b> DEBERES DE HOTEL EN RELACIÓN CON EL TRATAMIENTO DE
          LOS DATOS PERSONALES. APPEARS S.A.S tendrá presente, en todo momento,
          que los datos personales son propiedad de las personas a las que se
          refieren y que sólo ellas pueden decidir sobre los mismos. En este
          sentido, hará uso de ellos sólo para aquellas finalidades para las que
          se encuentra facultado debidamente, y respetando en todo caso la Ley
          1581 de 2012 sobre protección de datos personales.
        </p>
        <p class="politics-pro-data__parrafos">
          De conformidad con lo establecido en el artículo 17 de la Ley 1581 de
          2012 el HOTEL se compromete a cumplir en forma permanente con los
          siguientes deberes:
        </p>
        <p class="politics-pro-data__parrafos">
          a) Garantizar al Titular, en todo tiempo, el pleno y efectivo
          ejercicio del derecho de hábeas data.
        </p>
        <p class="politics-pro-data__parrafos">
          b) Conservar la información bajo las condiciones de seguridad
          necesarias para impedir su adulteración, pérdida, consulta, uso o
          acceso no autorizado o fraudulento.
        </p>
        <p class="politics-pro-data__parrafos">
          c) Realizar oportunamente, esto es en los términos previstos en los
          artículos 14 y 15 de la Ley 1581 de 2012, la actualización,
          rectificación o supresión de los datos.
        </p>
        <p class="politics-pro-data__parrafos">
          d) Tramitar las consultas y los reclamos formulados por los Titulares
          en los términos señalados en el artículo 14 de la Ley 1581 de 2012.
        </p>
        <p class="politics-pro-data__parrafos">
          e) Insertar en la base de datos la leyenda “información en discusión
          judicial” una vez notificado por parte de la autoridad competente
          sobre procesos judiciales relacionados con la calidad o detalles del
          dato personal.
        </p>
        <p class="politics-pro-data__parrafos">
          f) Abstenerse de circular información que esté siendo controvertida
          por el Titular y cuyo bloqueo haya sido ordenado por la
          Superintendencia de Industria y Comercio.
        </p>
        <p class="politics-pro-data__parrafos">
          g) Permitir el acceso a la información únicamente a las personas que
          pueden tener acceso a ella.
        </p>
        <p class="politics-pro-data__parrafos">
          h) Informar a la Superintendencia de Industria y Comercio cuando se
          presenten violaciones a los códigos de seguridad y existan riesgos en
          la administración de la información de los Titulares.
        </p>
        <p class="politics-pro-data__parrafos">
          i)Cumplir las instrucciones y requerimientos que imparta la
          Superintendencia de Industria y Comercio.
        </p>
        <p></p>
        <b class="politics-pro-data__parrafos politics-pro-data__center"
          >CAPÍTULO IV PROCEDIMIENTOS DE ACCESO, CONSULTA Y RECLAMACIÓN</b
        >
        <p></p>
        <p class="politics-pro-data__parrafos">
          <b>ARTÍCULO 13.</b> DERECHO DE ACCESO: El poder de disposición o
          decisión que tiene el titular sobre la información que le concierne,
          conlleva necesariamente el derecho de acceder y conocer si su
          información personal está siendo objeto de tratamiento, así como el
          alcance, condiciones y generalidades de dicho tratamiento.
        </p>
        <p class="politics-pro-data__parrafos">
          Asimismo, el titular tiene derecho a solicitar su rectificación en
          caso de ser inexactos o incompletos y a cancelarlos cuando no estén
          siendo utilizados conforme a finalidades y términos legales o
          contractuales o según las finalidades y términos contemplados en esta
          Política de Privacidad.
        </p>
        <p class="politics-pro-data__parrafos">
          APPEARS S.A.S garantizará el derecho de acceso cuando, previa
          acreditación de la identidad del titular o de su representante o
          apoderado lo solicite según lo previsto en la Ley 1581 de 2012.
        </p>
        <p class="politics-pro-data__parrafos">
          Los clientes y usuarios pueden ejercer sus derechos de conocer,
          actualizar, rectificar y suprimir sus datos personales enviando su
          solicitud al correo electrónico:
        </p>
        <p class="politics-pro-data__parrafos">
          reservas@hotelnatura.co y a través del teléfono 315 600 2345, de
          conformidad con esta Política de Privacidad.
        </p>
        <p class="politics-pro-data__parrafos">
          Debe incluir en la solicitud los siguientes datos:
        </p>
        <ul>
          <li class="politics-pro-data__parrafos">Nombres y apellidos.</li>
          <li class="politics-pro-data__parrafos">Tipo de documento.</li>
          <li class="politics-pro-data__parrafos">Número de documento.</li>
          <li class="politics-pro-data__parrafos">Teléfono.</li>
          <li class="politics-pro-data__parrafos">Correo electrónico.</li>
          <li class="politics-pro-data__parrafos">País.</li>
          <li class="politics-pro-data__parrafos">Asunto.</li>
        </ul>

        <p class="politics-pro-data__parrafos">
          <b>ARTÍCULO 13.</b> RESPUESTA A CONSULTAS. En cualquier caso,
          independientemente del mecanismo implementado para la atención de
          solicitudes de consulta, las mismas serán atendidas en un término
          máximo de diez (10) días hábiles contados a partir de la fecha de su
          recibo. Cuando no fuere posible atender la consulta dentro de dicho
          término, se informará al interesado antes del vencimiento de los 10
          días, expresando los motivos de la demora y señalando la fecha en que
          se atenderá su consulta, la cual en ningún caso podrá superar los
          cinco (5) días hábiles siguientes al vencimiento del primer plazo.
        </p>

        <p class="politics-pro-data__parrafos">
          <b>ARTÍCULO 14.</b> RECLAMOS. De conformidad con lo establecido en el
          artículo 14 de la Ley 1581 de 2012, el Titular o sus causahabientes
          que consideren que la información contenida en una base de datos debe
          ser objeto de corrección, actualización o supresión, o cuando
          adviertan el presunto incumplimiento de cualquiera de los deberes
          contenidos en la Ley 1581 de 2012, podrán presentar un reclamo ante el
          Responsable del Tratamiento, el cual será tramitado bajo las
          siguientes reglas:
        </p>
        <p class="politics-pro-data__parrafos">
          1. El reclamo lo podrá presentar el Titular en los formatos que al
          efecto dispone el hotel en su registro Hotelero. Si el reclamo
          recibido no cuenta con información completa que permita darle trámite,
          esto es, con la identificación del Titular, la descripción de los
          hechos que dan lugar al reclamo, la dirección, y acompañando los
          documentos que se quiera hacer valer, se requerirá al interesado
          dentro de los cinco (5) días siguientes a su recepción para que
          subsane las fallas.
        </p>
        <p class="politics-pro-data__parrafos">
          Transcurridos dos (2) meses desde la fecha del requerimiento sin que
          el solicitante presente la información requerida, se entenderá que ha
          desistido del reclamo. Si por alguna circunstancia la Compañía recibe
          un reclamo que en realidad no debería ir dirigido contra él, dará
          traslado a quien corresponda en un término máximo de dos (2) días
          hábiles e informará de la situación al interesado.
        </p>
        <p class="politics-pro-data__parrafos">
          2. Una vez recibido el reclamo completo, se incluirá en la base de
          datos que mantieAPPEARS S.A.S una leyenda que diga “reclamo en
          trámite” y el motivo del mismo, en un término no mayor a dos (2) días
          hábiles. Dicha leyenda deberá mantenerse hasta que el reclamo sea
          decidido.
        </p>
        <p class="politics-pro-data__parrafos">
          3. El término máximo para atender el reclamo será de quince (15) días
          hábiles contados a partir del día siguiente a la fecha de su recibo.
          Cuando no fuere posible atenderlo dentro de dicho término se informará
          al interesado antes del vencimiento del referido plazo los motivos de
          la demora y la fecha en que se atenderá su reclamo, la cual en ningún
          caso podrá superar los ocho (8) días hábiles siguientes al vencimiento
          del primer término.
        </p>

        <p class="politics-pro-data__parrafos">
          <b>ARTÍCULO 15.</b> IMPLEMENTACIÓN DE PROCEDIMIENTOS PARA GARANTIZAR
          EL DERECHO A PRESENTAR RECLAMOS. En cualquier momento y de manera
          gratuita el titular o su representante podrán solicitar a APPEARS
          S.A.S la rectificación, actualización o supresión de sus datos
          personales, previa acreditación de su identidad. Los derechos de
          rectificación, actualización o supresión únicamente se podrán ejercer
          por:
        </p>
        <ul>
          <li class="politics-pro-data__parrafos">
            El titular o sus causahabientes, previa acreditación de su
            identidad, o a través de instrumentos electrónicos que le permitan
            identificarse.
          </li>
          <li class="politics-pro-data__parrafos">
            Su representante, previa acreditación de la representación. Cuando
            la solicitud sea formulada por persona distinta del titular y no se
            acredite que la misma actúa en representación de aquél, se tendrá
            por no presentada.
          </li>
        </ul>
        <p class="politics-pro-data__parrafos">
          La solicitud de rectificación, actualización o supresión debe ser
          presentada a través de los medios habilitados por el HOTEL señalados
          en el aviso de privacidad y contener, como mínimo, la siguiente
          información:
        </p>
        <p class="politics-pro-data__parrafos">
          1. El nombre y domicilio del titular o cualquier otro medio para
          recibir la respuesta
        </p>
        <p class="politics-pro-data__parrafos">
          2. Los documentos que acrediten la identidad o la personalidad de su
          representante.
        </p>
        <p class="politics-pro-data__parrafos">
          3. La descripción clara y precisa de los datos personales respecto de
          los cuales el titular busca ejercer alguno de los derechos.
        </p>
        <p class="politics-pro-data__parrafos">
          4. En caso dado otros elementos o documentos que faciliten la
          localización de los datos personales.
        </p>

        <p class="politics-pro-data__parrafos">
          PARÁGRAFO 1. RECTIFICACIÓN Y ACTUALIZACIÓN DE DATOS. El APPEARS S.A.S
          tiene la obligación de rectificar y actualizar a solicitud del
          titular, la información de éste que resulte ser incompleta o inexacta,
          de conformidad con el procedimiento y los términos arriba señalados.
          Al respecto se tendrá en cuenta lo siguiente: En las solicitudes de
          rectificación y actualización de datos personales el titular debe
          indicar las correcciones a realizar y aportar la documentación que
          avale su petición.
        </p>
        <p class="politics-pro-data__parrafos">
          APPEARS S.A.S tiene plena libertad de habilitar mecanismos que le
          faciliten el ejercicio de este derecho, siempre y cuando éstos
          beneficien al titular. En consecuencia, se podrán habilitar medios
          electrónicos u otros que considere pertinentes.
        </p>
        <p class="politics-pro-data__parrafos">
          APPEARS S.A.S podrá establecer formularios, sistemas y otros métodos
          simplificados, mismos que deben ser informados en el aviso de
          privacidad y que se pondrán a disposición de los interesados en la
          página web.
        </p>
        <p class="politics-pro-data__parrafos">
          APPEARS S.A.S utilizará los servicios de atención o servicio al
          cliente que tiene en operación, siempre y cuando los plazos de
          respuesta no sean mayores a los señalados por el artículo 15 de la Ley
          1581 de 2012.
        </p>
        <p class="politics-pro-data__parrafos">
          Cada vez que el HOTEL ponga a disposición una herramienta nueva para
          facilitar el ejercicio de sus derechos por parte de los titulares de
          información o modifique las existentes, lo informará a través de su
          página web.
        </p>

        <p class="politics-pro-data__parrafos">
          PARÁGRAFO 2. SUPRESIÓN DE DATOS. El titular tiene el derecho, en todo
          momento, a solicitar al HOTEL la supresión (eliminación) de sus datos
          personales cuando:
        </p>
        <p class="politics-pro-data__parrafos">
          a.) Considere que los mismos no están siendo tratados conforme a los
          principios, deberes y obligaciones previstas en la Ley 1581 de 2012.
        </p>
        <p class="politics-pro-data__parrafos">
          b.) Hayan dejado de ser necesarios o pertinentes para la finalidad
          para la cual fueron recabados.
        </p>
        <p class="politics-pro-data__parrafos">
          c.) Se haya superado el periodo necesario para el cumplimiento de los
          fines para los que fueron recabados.
        </p>

        <p class="politics-pro-data__parrafos">
          Esta supresión implica la eliminación total o parcial de la
          información personal de acuerdo con lo solicitado por el titular en
          los registros, archivos, bases de datos o tratamientos realizados por
          APPEARS S.A.S. Es importante tener en cuenta que el derecho de
          cancelación no es absoluto y el responsable puede negar el ejercicio
          del mismo cuando:
        </p>
        <ul>
          <li class="politics-pro-data__parrafos">
            La solicitud de supresión de la información no procederán cuando el
            titular tenga un deber legal o contractual de permanecer en la base
            de datos.
          </li>
          <li class="politics-pro-data__parrafos">
            La eliminación de datos obstaculice actuaciones judiciales o
            administrativas vinculadas a obligaciones fiscales, la investigación
            y persecución de delitos o la actualización de sanciones
            administrativas.
          </li>
          <li class="politics-pro-data__parrafos">
            Los datos sean necesarios para proteger los intereses jurídicamente
            tutelados del titular; para realizar una acción en función del
            interés público, o para cumplir con una obligación legalmente
            adquirida por el titular. En caso de resultar procedente la
            cancelación de los datos personales, el HOTEL debe realizar
            operativamente la supresión de tal manera que la eliminación no
            permita la recuperación de la información.
          </li>
        </ul>

        <p class="politics-pro-data__parrafos">
          <b>ARTÍCULO 16.</b> REVOCATORIA DE LA AUTORIZACIÓN. Los titulares de
          los datos personales pueden revocar el consentimiento al tratamiento
          de sus datos personales en cualquier momento, siempre y cuando no lo
          impida una disposición legal. Para ello, deberán comunicarse con el
          APPEARS S.A.S, mediante correo electrónico: reservas@hotelnatura.co
          y/o a través del teléfono 315 600 2345.
        </p>
        <p class="politics-pro-data__parrafos">
          Se deberá tener en cuenta que existen dos modalidades en las que la
          revocación del consentimiento puede darse. La primera, puede ser sobre
          la totalidad de las finalidades consentidas, esto es, que el HOTEL
          deba dejar de tratar por completo los datos del titular; la segunda,
          puede ocurrir sobre tipos de tratamiento determinados, como por
          ejemplo para fines publicitarios o de estudios de mercado. Con la
          segunda modalidad, esto es, la revocación parcial del consentimiento,
          se mantienen a salvo otros fines del tratamiento que el responsable,
          de conformidad con la autorización otorgada puede llevar a cabo y con
          los que el titular está de acuerdo.
        </p>
        <p class="politics-pro-data__parrafos">
          Por lo anterior, será necesario que el titular al momento elevar la
          solicitud de revocatorio consentimiento APPEARS S.A.S, indique en ésta
          si la revocación que pretende realizar es total o parcial. En la
          segunda hipótesis se deberá indicar con cuál tratamiento el titular no
          está conforme. Habrá casos en que el consentimiento, por su carácter
          necesario en la relación entre titular y responsable por el
          cumplimiento de un contrato, por disposición legal no podrá ser
          revocado. Los mecanismos o procedimientos que el APPEARS S.A.S
          establezca para atender las solicitudes de revocatoria del
          consentimiento no podrán exceder los plazos previstos para atender las
          reclamaciones conforme se señala en el artículo 15 de la Ley 1581 de
          2012.
        </p>

        <p></p>
        <b class="politics-pro-data__parrafos politics-pro-data__center"
          >CAPITULO V SEGURIDAD DE LA INFORMACIÓN</b
        >
        <p></p>
        <p class="politics-pro-data__parrafos">
          <b>ARTÍCULO 17.</b> MEDIDAS DE SEGURIDAD. En desarrollo del principio
          de seguridad establecido en la Ley 1581 de 2012, el HOTEL ha adoptado
          las medidas técnicas, humanas y administrativas que sean necesarias
          para otorgar seguridad a los registros evitando su adulteración,
          pérdida, consulta, uso o acceso no autorizado o fraudulento.
        </p>
        <p class="politics-pro-data__parrafos">
          No obstante, lo anterior el cliente asume los riesgos que se derivan
          de entregar esta información en un medio como internet, el cual está
          sometido a diversas variables – ataques de terceros, fallas técnicas o
          tecnológicas, entre otras. el HOTEL hará su mejor esfuerzo tecnológico
          para garantizar la seguridad de la información personal de todos sus
          clientes y/o usuarios, empleando razonables y actuales métodos de
          seguridad para impedir el acceso no autorizado, para mantener la
          exactitud de los datos y garantizar la correcta utilización de la
          información.
        </p>

        <p></p>
        <b class="politics-pro-data__parrafos politics-pro-data__center"
          >CAPÍTULO VI DISPOSICIONES FINALES</b
        >
        <p></p>
        <p class="politics-pro-data__parrafos">
          <b>ARTÍCULO 19.</b> MODIFICACIONES A LA POLÍTICA DE PRIVACIDAD. El
          APPEARS S.A.S se reserva el derecho de efectuar en cualquier momento
          modificaciones o actualizaciones a esta Política de Privacidad, para
          la atención de novedades legislativas, políticas internas o nuevos
          requerimientos para la prestación u ofrecimiento de sus servicios o
          productos.
        </p>
        <p class="politics-pro-data__parrafos">
          <b>ARTICULO 20.</b> VIGENCIA DEL TRATAMIENTO DE LA INFORMACIÓN Y DATOS
          PERSONALES.
        </p>
        <p class="politics-pro-data__parrafos">
          La información suministrada por los clientes y usuarios permanecerá
          almacenada hasta por el término de quince (15) años contados a partir
          de la fecha del último tratamiento, para permitirnos el cumplimiento
          de las obligaciones legales y/o contractuales a su cargo especialmente
          en materia contable, fiscal y tributaria.
        </p>
      </div>
    </div>
    <footer-main></footer-main>
  </div>
</template>

<script>
import HeaderMain from "@/components/Header.vue";

import FooterMain from "@/components/Footer.vue";

export default {
  name: "PrivacyPolicy",
  components: {
    HeaderMain,
    FooterMain,
  },
  data() {
    return {};
  },
  metaInfo() {
    return {
      title: "Appears |",
      titleTemplate:
        "%s Agencia Digital | Estamos reestructurando nuestro sitio web.",
      meta: [
        {
          name: "description",
          content:
            "Somos un equipo preparado para desarrollar tu idea con el objetivo de llevarla a un nivel superior, descubrirás el impacto de la tecnología en esta era digital.",
        },
        {
          name: "keywords",
          content:
            "Appears, paginas web, paginas web villavicencio, sitio web, seo, sem, aplicaciones a la medida, software, google ads, facebook ads, publicidad en google, publicidad en facebook, publicidad en instagram, web, website, diseño web, desarrollo web",
        },
        {
          name: "author",
          content: "Appears",
        },
        {
          name: "robots",
          content: "Index, Follow",
        },
        {
          rel: "canonical",
          href: "https://appears.com.co/",
        },
        // Tags twitter
        {
          name: "twitter:card",
          content: "summary",
        },
        {
          name: "twitter:site",
          content: "@appears",
        },
        {
          name: "twitter:creator",
          content: "@appears",
        },
        {
          name: "twitter:title",
          content:
            "Appears | Agencia Digital | Estamos reestructurando nuestro sitio web.",
        },
        {
          name: "twitter:description",
          content:
            "Somos un equipo preparado para desarrollar tu idea con el objetivo de llevarla a un nivel superior, descubrirás el impacto de la tecnología en esta era digital.",
        },
        {
          name: "twitter:image",
          content: "https://appears.com.co/statics/img/img-str.png",
        },
        // Tags facebook
        {
          property: "og:locale",
          content: "es_CO",
        },
        {
          property: "og:site_name",
          content: "Appears",
        },
        {
          property: "og:url",
          content: "https://appears.com.co",
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          property: "og:title",
          content:
            "Appears | Agencia Digital | Estamos reestructurando nuestro sitio web.",
        },
        {
          property: "og:description",
          content:
            "Somos un equipo preparado para desarrollar tu idea con el objetivo de llevarla a un nivel superior, descubrirás el impacto de la tecnología en esta era digital.",
        },
        {
          property: "og:image",
          content: "https://appears.com.co/statics/img/img-str.png",
        },
        {
          property: "og:image:secure_url",
          content: "https://appears.com.co/statics/img/img-str.png",
        },
        {
          property: "og:image:width",
          content: "705",
        },
        {
          property: "og:image:height",
          content: "427",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.politics-pro-data {
  display: grid;
  background: #f9f9f9;
  padding: 50px 7%;
}
.politics-pro-data__content-h1 {
  justify-self: center;
  width: fit-content;
}
.politics-pro-data__h1 {
  margin: 0;
  padding: 0 10px;
  font-size: 19px;
  font-weight: bold;
  letter-spacing: 5px;
  line-height: 3.75rem;
  text-align: center;
}
.politics-pro-data__content {
  margin: 20px 0;
}
.politics-pro-data__parrafos {
  text-align: justify;
  font-size: 17px;
  line-height: normal;
}
.politics-pro-data__center {
  display: grid;
  text-align: center;
  margin: 50px 0;
  /* margin: auto; */
}
@media screen and (max-width: 1199px) {
  .politics-pro-data {
    padding: 50px 10%;
  }
}
@media screen and (max-width: 575px) {
  .politics-pro-data {
    padding: 50px 5%;
  }
}
</style>
